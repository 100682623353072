<template>
  <v-row>
    <v-col v-if="superAdmin" cols="12">
      <v-card flat>
        <v-card-title>
          <h5>Forecast</h5> <v-chip small class="ma-2" color="green" text-color="white">
            Beta
          </v-chip>
        </v-card-title>

        <v-card-subtitle>
          Based on your targeting
        </v-card-subtitle>

        <v-divider />

        <v-card-text class="">
          <div class="d-flex justify-space-between text-center">
            <div class="flex-column">
              <h5>Impressions</h5>
              <span>15B</span>
            </div>

            <div class="flex-column">
              <h5>Unique Users</h5>
              <span>5B</span>
            </div>

            <div class="flex-column">
              <h5>Total Spend</h5>
              <span>--</span>
            </div>

            <div class="flex-column">
              <h5>CPM</h5>
              <span>$1.5</span>
            </div>
          </div>
        </v-card-text>

        <v-card-text class="py-0">
          <apexchart type="line" height="275" :options="chartOptions" :series="series"></apexchart>
        </v-card-text>

      </v-card>
    </v-col>

    <v-col cols="12">
      <v-card class="mb-5" flat>
        <v-card-title class="py-2">
          <h5>Campaign Planner</h5>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row class="flex-column" dense>
            <v-col>
              <h5 class="mb-1">Country</h5>
              <v-combobox class="combo-scroll" :items="countries" item-text="name" outlined label="Choose Country"
                deletable-chips small-chips clearable dense multiple v-model="selected_countries"
                hide-details="auto"></v-combobox>
            </v-col>

            <v-col>
              <h5 class="mb-1">Gender</h5>
              <v-combobox class="combo-scroll" :items="genders" item-text="name" outlined label="Choose Gender"
                deletable-chips small-chips clearable dense multiple v-model="selected_gender"
                hide-details="auto"></v-combobox>
            </v-col>

            <v-col>
              <h5 class="mb-1">Age</h5>
              <v-combobox class="combo-scroll" :items="ages" item-text="name" outlined label="Choose Age Group(s)"
                deletable-chips small-chips clearable dense multiple v-model="selected_ages"
                hide-details="auto"></v-combobox>
            </v-col>

            <v-col>
              <h5>Verticals</h5>
              <v-combobox class="combo-scroll" :items="verticals" item-text="name" outlined label="Choose Vertical(s)"
                deletable-chips small-chips clearable dense multiple v-model="selected_verticals"
                hide-details="auto"></v-combobox>
            </v-col>

            <v-col>
              <h5>Devices</h5>
              <v-combobox class="combo-scroll" :items="devices" item-text="name" outlined label="Choose Device(s)"
                deletable-chips small-chips clearable dense multiple v-model="selected_devices"
                hide-details=""></v-combobox>
            </v-col>

            <v-col>
              <h5>Operating Systems</h5>
              <v-combobox class="combo-scroll" :items="os" item-text="name" outlined label="Choose Operating System(s)"
                deletable-chips small-chips clearable dense multiple v-model="selected_os" hide-details=""></v-combobox>
            </v-col>

            <v-col>
              <h5>Device Make</h5>
              <v-combobox class="combo-scroll" :items="make" item-text="name" outlined label="Choose Make" deletable-chips
                small-chips clearable dense multiple v-model="selected_make" hide-details=""></v-combobox>
            </v-col>

            <v-col>
              <h5 class="mb-1">Inventory Sizes</h5>
              <v-combobox class="combo-scroll" :items="inventory_sizes" item-text="name" outlined
                label="Choose Inventory Size(s)" deletable-chips small-chips clearable dense multiple
                v-model="selected_inventory_size" hide-details></v-combobox>
            </v-col>

            <v-col>
              <h5 class="mb-1">Format</h5>
              <v-combobox class="combo-scroll" :items="formats" item-text="name" outlined label="Choose Format(s)"
                deletable-chips small-chips clearable dense multiple v-model="selected_format"
                hide-details=""></v-combobox>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card flat class="mb-5">
        <v-card-title class="py-2">
          <h5>Audience Definition</h5>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <div>
                <img src="@/assets/audience_estimate_gauge.svg" />
                <img src="@/assets/centerpoint.svg" class="center-point" />
                <div class="arrow-container">
                  <div :style="arrow_wrapper" class="arrow-wrapper value-0">
                    <img src="@/assets/arrow.svg" class="arrow" />
                  </div>
                </div>
              </div>
              <div>
                <div class="d-flex flex-row">
                  <v-sheet class="ma-2 pa-2">Total Estimated Impressions</v-sheet>
                  <v-sheet class="ma-2 pa-2" v-if="impressions.length === 0"><strong>{{
                    total_impressions.toLocaleString("en-US")
                  }}</strong></v-sheet>
                  <v-sheet class="ma-2 pa-2" v-if="impressions.length != 0 && impressions.length > 1"><strong>{{
                    impressions
                      .reduce((partialSum, a) => partialSum + a, 0)
                      .toLocaleString("en-US")
                  }}</strong></v-sheet>
                  <v-sheet class="ma-2 pa-2" v-if="impressions.length === 1">
                    <strong>{{
                      impressions
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}</strong></v-sheet>
                </div>
                <div class="d-flex flex-row">
                  <v-sheet class="ma-2 pa-2">Total Uniques</v-sheet>
                  <v-sheet class="ma-2 pa-2" v-if="uniques.length === 0"><strong>{{
                    total_uniques.toLocaleString("en-US")
                  }}</strong></v-sheet>
                  <v-sheet class="ma-2 pa-2" v-if="uniques.length != 0 && uniques.length > 1"><strong>{{
                    uniques
                      .reduce((partialSum, a) => partialSum + a, 0)
                      .toLocaleString("en-US")
                  }}</strong></v-sheet>
                  <v-sheet class="ma-2 pa-2" v-if="uniques.length === 1"><strong>{{
                    uniques.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }}</strong></v-sheet>
                </div>
                <div class="d-flex flex-row mb-5">
                  <v-sheet class="ma-2 pa-2" id="long_measure" :style="long_measure"></v-sheet>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card flat class="mb-5">
        <v-card-title>
          <h5>Budget & Schedule</h5>
        </v-card-title>
        <v-card-text>Define how much you'd like to spend and your desired eCPM</v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <div>
                <v-select v-model="selectedBudget" :items="budgets" outlined dense></v-select>
              </div>
            </v-col>
            <v-col cols="6">
              <div class="dollar-input">
                <span class="dollar-sign">$</span>
                <v-text-field v-model="budget_amount" single-line label="Budget Amount" outlined dense type="number"
                  placeholder="$0.00" persistent-placeholder hide-details="auto"
                  @input="validatePositiveNumber"></v-text-field>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div>
                <h5 class="mb-1">eCPM</h5>
                <v-text-field v-model="CeCPM" single-line label="eCpm" outlined dense type="number" placeholder="0.1"
                  persistent-placeholder hide-details="auto" @input="validatePositiveNumber"></v-text-field>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="CeCPM != null && CeCPM != '' &&
            budget_amount != '' &&
            budget_amount != null &&
            selectedBudget === 'Daily Budget'
            ">
            <v-col>
              <p>
                <strong>Your Daily spend is ${{
                  budget_amount.toLocaleString("en-US")
                }}</strong>
              </p>
            </v-col>
          </v-row>
          <v-row v-if="selectedBudget === 'Daily Budget'"><v-col>
              <p><strong>Actual amount spent may vary.</strong></p>
            </v-col></v-row>
          <v-row v-if="selectedBudget === 'Daily Budget'
            ">
            <v-col>
              <p>
                <strong>Estimated Daily Impressions are
                  {{
                    ((budget_amount * 1000) / CeCPM).toLocaleString("en-US")
                  }}
                  of </strong><strong>{{
                    total_impressions.toLocaleString("en-US")
                  }}</strong>
              </p>
            </v-col>
          </v-row>
          <v-row v-if="CeCPM != null && CeCPM != '' &&
              budget_amount != '' &&
              budget_amount != null &&
              selectedBudget === 'Lifetime Budget'
              ">
            <v-col>
              <p>
                <strong>Your Lifetime spend is ${{
                  budget_amount.toLocaleString("en-US")
                }}</strong>
              </p>
            </v-col>
          </v-row>
          <v-row v-if="selectedBudget === 'Lifetime Budget'"><v-col>
              <p><strong>Actual amount spent may vary.</strong></p>
            </v-col></v-row>
          <v-row v-if="CeCPM != null &&
            budget_amount != '' &&
            budget_amount != null &&
            selectedBudget === 'Lifetime Budget'
            ">
            <v-col>
              <p>
                <strong>Estimated Lifetime Impressions
                  {{
                    ((budget_amount * 1000) / CeCPM).toLocaleString("en-US")
                  }}
                  of </strong><strong>{{
                    total_impressions.toLocaleString("en-US")
                  }}</strong>
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import campainPlanningMixin from "../../mixins/campaignPlanningMixin";

export default {
  beforeMount() {
    this.lowerBoundCost = (this.CeCPM * this.total_uniques) / 1000;
    this.upperBoundCost = (this.CeCPM * this.total_impressions) / 1000;
  },
  mixins: [campainPlanningMixin],
  data: () => ({
    total_impressions: 62270000000,
    total_uniques: 157200000,
    impressions: [],
    selected_countries: [],
    selected_gender: [],
    selected_verticals: [],
    selected_ages: [],
    selected_devices: [],
    selected_os: [],
    selected_make: [],
    selected_inventory_size: [],
    selected_format: [],
    uniques: [],
    selected_values: [],
    total_impressions_cost: null,
    CeCPM: 0.1,
    budgets: ["Daily Budget", "Lifetime Budget"],
    selectedBudget: "Daily Budget",
    budget_amount: 0.0,

    series: [{
      data: [0, 20, 30, 40, 50, 60, 70, 80, 90]
    }],
    chartOptions: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        },
        toolbar: { show: false },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 2
      },
      grid: {
        show: false,
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return val;
          },
        },
        title: {
          text: 'Impressions'
        }
      },
      xaxis: {
        title: {
          text: 'Total Spend ($)'
        },
        categories: ['0.0', '0.3', '0.6', '0.9', '1.2', '1.5', '1.8', '2.1', '2.4'],
      }
    },
  }),


  computed: {
    superAdmin() {
      return this.$store.getters.superAdmin;
    },
    arrow_wrapper() {
      let currentValue = this.impressions.reduce(
        (partialSum, a) => partialSum + a,
        0
      );
      //let previousValue = currentValue;
      let lowerThreshold = 0.3 * this.total_impressions;
      let upperThreshold = 0.6 * this.total_impressions;
      if (currentValue > 0 && currentValue < lowerThreshold) {
        //small
        return "transform: rotate(-80deg);@keyframes rotate { 0% { transform: rotate(-90deg);}100% {transform: rotate(-20deg);}}";
      } else if (
        currentValue > lowerThreshold &&
        currentValue <= upperThreshold
      ) {
        //middle
        return "transform: rotate(-20deg);@keyframes rotate { 0% { transform: rotate(-20deg);}100% {transform: rotate(40deg);}}";
      } else if (
        currentValue >= upperThreshold &&
        currentValue <= this.total_impressions
      ) {
        //large
        return "transform: rotate(40deg);@keyframes rotate { 0% { transform: rotate(40deg);}100% {transform: rotate(90deg);}}";
      } else {
        return "transform: rotate(90deg)";
      }
    },
    long_measure() {
      let value = this.impressions.reduce((partialSum, a) => partialSum + a, 0);
      let lowerThreshold = 0.3 * this.total_impressions;
      let upperThreshold = 0.6 * this.total_impressions;
      if (value > upperThreshold) {
        return "background: linear-gradient(to right, rgb(84, 199, 236) 80%, rgb(233, 235, 238) 20%)";
      } else if (value > lowerThreshold && value <= upperThreshold) {
        return "background: linear-gradient(to right, rgb(84, 199, 236) 60%, rgb(233, 235, 238) 40%)";
      } else if (value > 0 && value < lowerThreshold) {
        return "background: linear-gradient(to right, rgb(84, 199, 236) 20%, rgb(233, 235, 238) 20%)";
      } else {
        return "background: linear-gradient(to right, rgb(84, 199, 236) 100%, rgb(233, 235, 238) 0%)";
      }
    },

    calculateImpressions() {
      return (this.budget_amount * 1000) / this.CeCPM;
    },
  },
  methods: {
    convert(num) {
      if (num > 999 && num < 1000000) {
        return (num / 1000).toFixed(1) + "K";
      } else if (num > 1000000 && num < 1000000000) {
        return (num / 1000000).toFixed(1) + "M";
      } else if (num > 1000000000 && num < 1000000000000) {
        return num / 1000000000 + "B";
      } else if (num > 1000000000) {
        return num / 1000000000000 + "T";
      } else if (num < 900) {
        return num;
      }
    },

    calculateEstiamtedCost() {
      if (this.impressions.length && this.uniques.length) {
        this.lowerBoundCost =
          (this.CeCPM *
            this.uniques.reduce(
              (accumulator, currentValue) => accumulator + currentValue,
              0
            )) /
          1000;
        this.upperBoundCost =
          (this.CeCPM *
            this.impressions.reduce(
              (accumulator, currentValue) => accumulator + currentValue,
              0
            )) /
          1000;
      }
      if (!this.impressions.length) {
        let estimated_cost = (this.CeCPM * this.total_impressions) / 1000;
        this.estimatedCost = estimated_cost;
      }
    },
    validatePositiveNumber() {
      // Remove any non-digit characters
      // this.budget_amount = this.budget_amount.replace(/\D/g, "");

      // Ensure the value is a positive number
      const parsedValue = parseFloat(this.budget_amount);
      this.budget_amount = isNaN(parsedValue) ? null : Math.abs(parsedValue);

      // Ensure CeCPM is a positive number
      this.CeCPM = parseFloat(this.CeCPM);
      this.CeCPM = isNaN(this.CeCPM) ? null : Math.abs(this.CeCPM);

      // this.calculateImpressions;
    },
  },
};
</script>

<style scoped>
.center-point {
  background-color: rgb(136, 131, 131);
  border-radius: 50%;
  position: absolute;
  top: 220px;
  left: 190px;
  z-index: 10;
}

.arrow-container {
  width: 160px;
  height: 160px;
  background-color: transparent;
  position: absolute;
  z-index: 12;
  top: 151px;
  left: 120px;
}

.arrow-wrapper {
  width: 160px;
  height: 160px;
  background-color: transparent;
  position: relative;
  transition: all 0.4s;
}

.arrow {
  position: absolute;
  top: -56.88px;
  left: 54.5px;
}

#long_measure {
  display: block;
  border: 0.5px solid whitesmoke;
  width: 400px;
  height: 12px;
  position: relative;
}

.card-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 2px;
}

.card {
  background-color: #ffffff;
  padding: 20px;
}

.dollar-input {
  display: flex;
  align-items: center;
}

.dollar-sign {
  margin-right: 5px;
}
</style>
