export default {
  data: () => ({
    total_impressions: 62270000000,
    total_uniques: 157200000,
    countries: [
      {
        name: "Kenya",
        total_impressions: 6009000000,
        total_uniques: 19320000,
        fraction:0.096499116749639,
        genders: [
          { name: "Male", value: 0.47 },
          { name: "Female", value: 0.42 },
        ],
        ages: [
          { name: "18-24", value: 0.19 },
          { name: "25-34", value: 0.26 },
          { name: "35-44", value: 0.30 },
          { name: "45-54", value: 0.05 },
          { name: "55-64", value: 0.08 },
          { name: "65+", value: 0.02 },
        ],
        verticals: [
          { name: "Internet&Telecom", value: 0.8 },
          { name: "Computers & Electronics", value: 0.088 },
          { name: "News", value: 0.058 },
          { name: "Arts & Entertainment", value: 0.03 },
          { name: "Games", value: 0.03 },
          { name: "People & Society", value: 0.022 },
          { name: "Sports", value: 0.02 },
          { name: "Law & Government", value: 0.016 },
          { name: "Reference", value: 0.012 },
          { name: "Business & Industrial", value: 0.01 }, 
        ],
        devices: [
          { name: "Mobile", value: 0.94 },
          { name: "Desktop", value: 0.003 },
          { name: "Tablet", value: 0.0006 },
          { name: "ConnectedTV", value: 0.0564 },
        ],
        os :[
          { name: "Android", value:0.64},
          { name: "IOS", value: 0.02},
          { name: "Windows", value: 0.19},
          { name: "OS X", value: 0.01},
          { name: "Linux", value: 0.009}
        ],
        make:[
          { name:"Tecno", value:0.2131},
          { name:"Samsung", value:0.2090},
          { name:"Infinix", value:0.1036},
          { name:"Oppo", value:0.0749},
          { name:"Nokia", value:0.0587},
          { name:"Huawei", value:0.0462},
          { name:"Xiaomi", value:0.0375},
          { name:"Apple", value:0.0339},
          { name:"Itel", value:0.03084},
          { name:"Safaricom", value:0.0160},
          { name:"Realme", value:0.0136},
          { name:"Vivo", value:0.0073},
          { name:"Vodafone", value:0.0062},
          { name:"Infinix", value:0.0027},
          { name:"OnePlus", value:0.0026},
          { name:"Google", value:0.0018},
          { name:"Lenovo", value:0.0017},
          { name:"Motorola", value:0.0011},
          { name:"HTC", value:0.0012},
          { name:"LG", value:0.000807},
          { name:"Wiko", value:0.0008},
          { name:"Sony", value:0.0006},
          { name:"Alcatel", value:0.0005},
          { name:"Gionee", value:0.0003},
          { name:"Hisense", value:0.0003},
          { name:"ZTE", value:0.0003},
          { name:"Lava", value:0.0002},
          { name:"Umidigi", value:0.0001}
        ],
        inventory_sizes: [
          { name: "Native", value: 0.49 },
          { name: "320x50", value: 0.2244 },
          { name: "300x250", value: 0.1683 },
          { name: "320x100", value: 0.1224 },
          { name: "300x100", value: 0.1122 },
          { name: "300x50", value: 0.1071 },
          { name: "Instertitial", value: 0.0663 },
          { name: "250x250", value: 0.0612 },
          { name: "336x280", value: 0.051 },
          { name: "200x200", value: 0.0459 },
        ],
        formats: [
          { name: "Display", value: 0.79 },
          { name: "Video", value: 0.13 },
          { name: "Audio", value: 0.08 },
        ],
      },
      {
        name: "Nigeria",
        total_impressions: 17560000000,
        total_uniques: 50120000,
        fraction:0.281997751726353,
        genders: [
          { name: "Male", value: 0.62 },
          { name: "Female", value: 0.24 },
        ],
        ages: [
          { name: "18-24", value: 0.15 },
          { name: "25-34", value: 0.22 },
          { name: "35-44", value: 0.25 },
          { name: "45-54", value: 0.07 },
          { name: "55-64", value: 0.12 },
          { name: "65+", value: 0.03 },
        ],
        verticals: [
          { name: "Internet&Telecom", value: 0.87 },
          { name: "Computers & Electronics", value: 0.0468 },
          { name: "News", value: 0.0234 },
          { name: "Arts & Entertainment", value: 0.0377 },
          { name: "Games", value: 0.0182 },
          { name: "People & Society", value: 0.013 },
          { name: "Online Communities", value: 0.0091 },
          { name: "Law & Government", value: 0.0065 },
          { name: "Reference", value: 0.0065 },
          { name: "Books & Literature", value: 0.0065 },
        ],
        devices: [
          { name: "Mobile", value: 0.97 },
          { name: "Desktop", value: 0.0006 },
          { name: "Tablet", value: 0.0003 },
          { name: "ConnectedTV", value: 0.0291 },
        ],
        os :[
          { name: "Android", value:0.70},
          { name: "IOS", value: 0.10},
          { name: "Windows", value: 0.08},
          { name: "OS X", value: 0.008},
          { name: "Linux", value: 0.007}
        ],
        make:[
          { name:"Tecno", value:0.245},
          { name:"Samsung", value:0.1255},
          { name:"Infinix", value:0.2043},
          { name:"Oppo", value:0.0192},
          { name:"Nokia", value:0.0202},
          { name:"Huawei", value:0.0325},
          { name:"Xiaomi", value:0.0344},
          { name:"Apple", value:0.1292},
          { name:"Itel", value:0.03084},
          { name:"Realme", value:0.0456},
          { name:"Vivo", value:0.0139},
          { name:"OnePlus", value:0.0007},
          { name:"Google", value:0.00102},
          { name:"Lenovo", value:0.00084},
          { name:"Motorola", value:0.0017},
          { name:"HTC", value:0.0007},
          { name:"LG", value:0.0016},
          { name:"Wiko", value:0.0001},
          { name:"Sony", value:0.0005},
          { name:"Alcatel", value:0.0003},
          { name:"Gionee", value:0.0086},
          { name:"ZTE", value:0.0009},
          { name:"Lava", value:0.0002},
          { name:"Umidigi", value:0.0001},
          { name:"BBK", value:0.0036},
          { name:"White Label", value:0.0018},
          { name:"Coolpad", value:0.0003},
          { name:"Asus", value:0.0002},
          { name:"RIM", value:0.0001},
          { name:"Chine Mobile", value:0.0001},
          { name:"Meizu", value:0.0001},
          { name:"LeEco", value:0.0001},
        ],
        inventory_sizes: [
          { name: "Native", value: 0.43 },
          { name: "320x50", value: 0.114 },
          { name: "300x250", value: 0.0969 },
          { name: "320x480", value: 0.0228 },
          { name: "234x60", value: 0.0342 },
          { name: "300x50", value: 0.0912 },
          { name: "Instertitial", value: 0.0855 },
          { name: "250x250", value: 0.0627 },
          { name: "336x280", value: 0.0912 },
          { name: "200x200", value: 0.0385 },
        ],
        formats: [
          { name: "Display", value: 0.86 },
          { name: "Video", value: 0.08 },
          { name: "Audio", value: 0.06 },
        ],
      },
      {
        name: "South Africa",
        total_impressions: 13840000000,
        total_uniques: 29700000,
        fraction:0.222257909105508,
        genders: [
          { name: "Male", value: 0.52 },
          { name: "Female", value: 0.3 },
        ],
        ages: [
          { name: "18-24", value: 0.08 },
          { name: "25-34", value: 0.185 },
          { name: "35-44", value: 0.16 },
          { name: "45-54", value: 0.12 },
          { name: "55-64", value: 0.18 },
          { name: "65+", value: 0.1 },
        ],
        verticals: [
          { name: "Internet&Telecom", value: 0.79 },
          { name: "Computers & Electronics", value: 0.0315 },
          { name: "News", value: 0.0294 },
          { name: "Arts & Entertainment", value: 0.0336 },
          { name: "Games", value: 0.1113 },
          { name: "People & Society", value: 0.0357 },
          { name: "Online Communities", value: 0.0147 },
          { name: "Business & Industrial", value: 0.0147 },
          { name: "Reference", value: 0.0126 },
          { name: "Books & Literature", value: 0.0126 },
        ],
        devices: [
          { name: "Mobile", value: 0.86 },
          { name: "Desktop", value: 0.0112 },
          { name: "Tablet", value: 0.0084 },
          { name: "ConnectedTV", value: 0.1204 },
        ],
        os :[
          { name: "Android", value:0.65},
          { name: "IOS", value: 0.14},
          { name: "Windows", value: 0.12},
          { name: "OS X", value: 0.1},
          { name: "Linux", value: 0.0065}
        ],
        make:[
          { name:"Tecno", value:0.0064},
          { name:"Samsung", value:0.4873},
          { name:"Infinix", value:0.00083},
          { name:"Oppo", value:0.024},
          { name:"Nokia", value:0.0207},
          { name:"Huawei", value:0.1968},
          { name:"Xiaomi", value:0.0272211},
          { name:"Apple", value:0.1744},
          { name:"Itel", value:0.0029},
          { name:"Realme", value:0.0002},
          { name:"Vivo", value:0.0134},
          { name:"OnePlus", value:0.0008},
          { name:"Google", value:0.0006},
          { name:"Lenovo", value:0.0015},
          { name:"Motorola", value:0.0012},
          { name:"HTC", value:0.0001},
          { name:"LG", value:0.0059},
          { name:"Sony", value:0.0026},
          { name:"Alcatel", value:0.0004},
          { name:"ZTE", value:0.0008},
          { name:"Honor", value:0.0034},
          { name:"Mobicel", value:0.0012},
          { name:"Vodafone", value:0.0012},
          { name:"CAT", value:0.001},
          { name:"Caterpillar", value:0.0003},
          { name:"Realme", value:0.0002},
          { name:"MobiWire", value:0.0001},
        ],
        inventory_sizes: [
          { name: "Native", value: 0.31 },
          { name: "320x50", value: 0.2415 },
          { name: "300x250", value: 0.1311 },
          { name: "234x60", value: 0.0621 },
          { name: "234x60", value: 0.0621 },
          { name: "300x50", value: 0.1586 },
          { name: "Instertitial", value: 0.1586 },
          { name: "250x250", value: 0.069 },
          { name: "336x280", value: 0.0621 },
          { name: "300x100", value: 0.0483 },
        ],
        formats: [
          { name: "Display", value: 0.63 },
          { name: "Video", value: 0.27 },
          { name: "Audio", value: 0.1 },
        ],
      },
      {
        name: "Senegal",
        total_impressions: 1739000000,
        total_uniques: 6104000,
        fraction:0.027926770515497,
        genders: [
          { name: "Male", value: 0.57 },
          { name: "Female", value: 0.28 },
        ],
        ages: [
          { name: "18-24", value: 0.26 },
          { name: "25-34", value: 0.37 },
          { name: "35-44", value: 0.09 },
          { name: "45-54", value: 0.09 },
          { name: "55-64", value: 0.04 },
          { name: "65+", value: 0.02 },
        ],
        verticals: [
          { name: "Internet&Telecom", value: 0.92 },
          { name: "Computers & Electronics", value: 0.024 },
          { name: "News", value: 0.024 },
          { name: "Arts & Entertainment", value: 0.0088 },
          { name: "Games", value: 0.0256 },
          { name: "People & Society", value: 0.064 },
          { name: "Online Communities", value: 0.0016 },
          { name: "Law & Government", value: 0.0024 },
          { name: "Reference", value: 0.0016 },
          { name: "Sports", value: 0.0206 },
        ],
        devices: [
          { name: "Mobile", value: 0.96 },
          { name: "Desktop", value: 0.016 },
          { name: "Tablet", value: 0.016 },
          { name: "ConnectedTV", value: 0.008 },
        ],
        os :[
          { name: "Android", value:0.53},
          { name: "IOS", value: 0.23},
          { name: "Windows", value: 0.16},
          { name: "OS X", value: 0.01},
          { name: "Linux", value: 0.0027}
        ],
        make:[
          { name:"Tecno", value:0.179},
          { name:"Samsung", value:0.3687},
          { name:"Infinix", value:0.0112},
          { name:"Oppo", value:0.0061},
          { name:"Nokia", value:0.0045},
          { name:"Huawei", value:0.0403},
          { name:"Xiaomi", value:0.0307},
          { name:"Apple", value:0.2728},
          { name:"Itel", value:0.0268},
          { name:"Realme", value:0.0032},
          { name:"Vivo", value:0.0014},
          { name:"OnePlus", value:0.0012},
          { name:"Google", value:0.0013},
          { name:"Lenovo", value:0.0010},
          { name:"Motorola", value:0.0045},
          { name:"HTC", value:0.0005},
          { name:"LG", value:0.0037},
          { name:"Wiko", value:0.0014},
          { name:"Sony", value:0.0022},
          { name:"ZTE", value:0.0011},
          { name:"TCL", value:0.0008},
          { name:"HTC", value:0.0005},
          { name:"Asus", value:0.0005},
          { name:"Vodafone", value:0.0004},
          { name:"BBK", value:0.0002},
          { name:"Honor", value:0.0003},
          { name:"Orange", value:0.0003},
          { name:"RIM", value:0.0002},
          { name:"bq", value:0.0003},
          { name:"Archos", value:0.0003},
        ],
        inventory_sizes: [
          { name: "Native", value: 0.48 },
          { name: "320x50", value: 0.182 },
          { name: "300x250", value: 0.1092 },
          { name: "300x100", value: 0.0312 },
          { name: "468x60", value: 0.0364 },
          { name: "300x50", value: 0.1144 },
          { name: "Instertitial", value: 0.104 },
          { name: "250x250", value: 0.0364 },
          { name: "336x280", value: 0.0312 },
          { name: "300x100", value: 0.0312 },
        ],
        formats: [
          { name: "Display", value: 0.61 },
          { name: "Video", value: 0.30 },
          { name: "Audio", value: 0.09 },
        ],
      },
      {
        name: "Ethiopia",
        total_impressions: 1691000000,
        total_uniques: 7599000,
        fraction:0.027155933836518,
        genders: [
          { name: "Male", value: 0.62 },
          { name: "Female", value: 0.34 },
        ],
        ages: [
          { name: "18-24", value: 0.078949152542373},
          { name: "25-34", value: 0.778983050847458 },
          { name: "35-44", value: 0.083661016949153 },
          { name: "45-54", value: 0.015772881355932 },
          { name: "55-64", value: 0.003461016949153 },
          { name: "65+", value: 0.006874576271186 },
        ],
        verticals: [
          { name: "Internet&Telecom", value: 0.614477160370037 },
          { name: "Computers & Electronics", value: 0.223208447044668},
          { name: "News", value: 0.026179037587953924 },
          { name: "Arts & Entertainment", value: 0.03027700517041449 },
          { name: "Games", value: 0.047998535194566136 },
          { name: "People & Society", value: 0.013708573471327548 },
          { name: "Sports", value: 0.019033751558535535 },
          { name: "Books & Literature", value: 0.004023855402777853 },
          { name: "Reference", value: 0.011663949220077997 },
          { name: "Business & Industrial", value: 0.009429684979640933 },
        ],
        devices: [
          { name: "Mobile", value: 0.960677966101695 },
          { name: "Desktop", value: 0.02368813559322},
          { name: "Tablet", value:0.014742372881356 },
          { name: "ConnectedTV", value: 0.000719661016949 },
        ],
        os :[
          { name: "Android", value:0.50},
          { name: "IOS", value: 0.33},
          { name: "Windows", value: 0.07},
          { name: "OS X", value: 0.006},
          { name: "Linux", value: 0.03}
        ],
        make:[
          { name:"Tecno", value:0.1508},
          { name:"Samsung", value:0.4665},
          { name:"Infinix", value:0.0721},
          { name:"Oppo", value:0.0044},
          { name:"Nokia", value:0.0109},
          { name:"Huawei", value:0.0942},
          { name:"Xiaomi", value:0.0093},
          { name:"Apple", value:0.0464},
          { name:"Itel", value:0.034},
          { name:"Realme", value:0.0055},
          { name:"Vivo", value:0.0019},
          { name:"OnePlus", value:0.0017},
          { name:"Google", value:0.0013},
          { name:"Lenovo", value:0.0126},
          { name:"Motorola", value:0.0045},
          { name:"HTC", value:0.0017},
          { name:"LG", value:0.0040},
          { name:"Wiko", value:0.0002},
          { name:"Sony", value:0.0011},
          { name:"Alcatel", value:0.0006},
          { name:"T-Mobile", value:0.0003},
          { name:"Lava", value:0.0006},
          { name:"Honor", value:0.0003},
          { name:"BBK", value:0.0006},
          { name:"White Label", value:0.0007},
          { name:"Coolpad", value:0.0002},
          { name:"Asus", value:0.0019},
          { name:"Infinex", value:0.0019},
          { name:"Hisense", value:0.00009},
          { name:"Vodafone", value:0.00026},
          { name:"Blu", value:0.0002},
          { name:"Micromax", value:0.0002},
        ],
        inventory_sizes: [
          { name: "Native", value: 0.30766275112526087 },
          { name: "320x50", value: 0.2513997145680096 },
          { name: "300x250", value: 0.2722582061697224 },
          { name: "320x100", value: 0.0494016906356348 },
          // { name: "300x100", value:0 },
          // { name: "300x50", value: 0},
          { name: "Instertitial", value: 0.0029366560544518004 },
          // { name: "250x250", value: 0 },
          { name: "336x280", value: 0.11634098144692044 },
          // { name: "200x200", value: 0 },
          // { name: "234x60", value: 0 },
        ],
        formats: [
          { name: "Display", value: 0.87 },     
          { name: "Video", value: 0.12 },
          { name: "Audio", value: 0.01 },
        ], 
      },
      {
        name: "Uganda",
        total_impressions:949700000,
        total_uniques: 4921000,
        fraction:0.015251324875542,
        genders: [
          { name: "Male", value: 0.56 },
          { name: "Female", value: 0.33 },
        ],
        ages: [
          { name: "18-24", value: 0.40},
          { name: "25-34", value: 0.35 },
          { name: "35-44", value: 0.11 },
          { name: "45-54", value: 0.03 },
          { name: "55-64", value: 0.02 },
          { name: "65+", value: 0.01 },
        ],
        verticals: [
          { name: "Internet&Telecom", value: 0.77},
          { name: "Computers & Electronics", value:0.27},
          { name: "News", value:0.14},
          { name: "Arts & Entertainment", value: 0.18},
          { name: "Games", value:0.14},
          { name: "People & Society", value:0.07},
          { name: "Sports", value:0.07},
          { name: "Online Communities", value:0.04},
          { name: "Reference", value:0.07},
          { name: "Hobbies & Leisure", value:0.03},
        ],
        devices: [
          { name: "Mobile", value:0.90},
          { name: "Desktop", value:0.07},
          { name: "Tablet", value:0.02},
          { name: "ConnectedTV", value:0.01},
        ],
        os :[
          { name: "Android", value:0.59},
          { name: "IOS", value: 0.05},
          { name: "Windows", value: 0.16},
          { name: "OS X", value: 0.0105},
          { name: "Linux", value: 0.005}
        ],
        make:[
          { name:"Tecno", value:0.2698},
          { name:"Samsung", value:0.1898},
          { name:"Infinix", value:0.1042},
          { name:"Oppo", value:0.0168},
          { name:"Nokia", value:0.0423},
          { name:"Huawei", value:0.0228},
          { name:"Xiaomi", value:0.0244},
          { name:"Apple", value:0.0652},
          { name:"Itel", value:0.0427},
          { name:"Realme", value:0.0038},
          { name:"Vivo", value:0.0037},
          { name:"OnePlus", value:0.002},
          { name:"Google", value:0.0025},
          { name:"Lenovo", value:0.0023},
          { name:"Motorola", value:0.0027},
          { name:"HTC", value:0.0007},
          { name:"LG", value:0.0025},
          { name:"Wiko", value:0.00013},
          { name:"Sony", value:0.0058},
          { name:"Alcatel", value:0.0006},
          { name:"Sharp", value:0.0084},
          { name:"ZTE", value:0.0003},
          { name:"Lava", value:0.0004},
          { name:"Infinex", value:0.0047},
          { name:"BBK", value:0.0007},
          { name:"Hisense", value:0.0006},
          { name:"Fujitzu", value:0.0004},
          { name:"Honor", value:0.0002},
          { name:"Micromax", value:0.00016},
          { name:"Fujitsu", value:0.0001},
        ],
        inventory_sizes: [
          { name: "Native", value:0.21},
          { name: "320x50", value:0.24},
          { name: "300x250", value:0.19},
          // { name: "320x100", value:0.15},
          { name: "300x50", value:0.19},
          { name: "Instertitial", value:0.24},
          { name: "250x250", value:0.14},
          { name: "336x280", value:0.12},
          { name: "200x200", value:0.10},
          { name: "234x60", value:0.08},
          { name: "180x150", value:0.07}
        ],
        formats: [
          { name: "Display", value:0.72},
          { name: "Video", value:0.11},
          { name: "Audio", value:0.6},
        ],
      },
      {
        name: "Botswana",
        total_impressions:180800000,
        total_uniques:553000,
        fraction:0.002903484824153,
        genders: [
          { name: "Male", value: 0.53},
          { name: "Female", value: 0.33},
        ],
        ages: [
          { name: "18-24", value: 0.29 },
          { name: "25-34", value: 0.25 },
          { name: "35-44", value: 0.20},
          { name: "45-54", value: 0.06 },
          { name: "55-64", value: 0.06},
          { name: "65+", value: 0.03 },
        ],
        verticals: [
          { name: "Internet&Telecom", value: 0.81 },
          { name: "Computers & Electronics", value: 0.19 },
          { name: "News", value: 0.08 },
          { name: "Arts & Entertainment", value: 0.37 },
          { name: "Games", value: 0.47 },
          { name: "People & Society", value: 0.30 },
          { name: "Online Communities", value: 0.13 },
          { name: "Business & Industrial", value: 0.04 },
          { name: "Reference", value: 0.07},
          { name: "Books & Literature", value: 0.05},
        ],
        devices: [
          { name: "Mobile", value: 0.87 },
          { name: "Desktop", value: 0.09 },
          { name: "Tablet", value: 0.03 },
          { name: "ConnectedTV", value: 0.001 },
        ],
        os :[
          { name: "Android", value:0.51},
          { name: "IOS", value: 0.05},
          { name: "Windows", value: 0.36},
          { name: "OS X", value: 0.0218},
          { name: "Linux", value: 0.0037}
        ],
        make:[		
          { name:"Tecno", value:0.1176},
          { name:"Samsung", value:0.4478},
          { name:"Infinix", value:0.0102},
          { name:"Oppo", value:0.00405},
          { name:"Nokia", value:0.0140},
          { name:"Huawei", value:0.2182},
          { name:"Xiaomi", value:0.0342},
          { name:"Apple", value:0.0918},
          { name:"Itel", value:0.0141},
          { name:"Realme", value:0.0004},
          { name:"Vivo", value:0.0076},
          { name:"OnePlus", value:0.0024},
          { name:"Google", value:0.0007},
          { name:"Lenovo", value:0.0055},
          { name:"Motorola", value:0.0006},
          { name:"LG", value:0.0003},
          { name:"Sony", value:0.00074},
          { name:"Alcatel", value:0.0003},
          { name:"Hisense", value:0.0062},
          { name:"ZTE", value:0.0003},
          { name:"Mobicel", value:0.0006},
          { name:"Vodafone", value:0.0009},
          { name:"BBK", value:0.0001},
          { name:"CAT", value:0.0004},
          { name:"Caterpillar", value:0.0004},
          { name:"Panasonic", value:0.0001},
          { name:"MobiWire", value:0.0002},
          { name:"Meizu", value:0.0002},
          { name:"Honor", value:0.0004},
          { name:"Orange", value:0.0000923},
          { name:"Blu", value:0.0005},
        ],
        inventory_sizes: [
          { name: "Native", value: 0.27 },
          { name: "320x50", value: 0.33 },
          { name: "300x250", value: 0.16 },
          { name: "234x60", value: 0.07 },
          { name: "250x250", value: 0.09},
          { name: "300x50", value: 0.22 },
          { name: "Instertitial", value: 0.23 },
          { name: "336x280", value: 0.07 },
          { name: "320x100", value: 0.05 },
          { name: "300x100", value: 0.05 },
        ],
        formats: [
          { name: "Display", value: 0.59 },
          { name: "Video", value: 0.23 },
          { name: "Audio", value: 0.01 },
        ],
      },
      {
        name: "Angola",
        total_impressions: 775600000,
        total_uniques: 2586000,
        fraction:0.012455436004497,
        genders: [
          { name: "Male", value: 0.53 },
          { name: "Female", value: 0.29 },
        ],
        ages: [
          { name: "18-24", value: 0.14 },
          { name: "25-34", value: 0.42 },
          { name: "35-44", value: 0.17 },
          { name: "45-54", value: 0.05 },
          { name: "55-64", value: 0.05 },
          { name: "65+", value: 0.02 },
        ],
        verticals: [
          { name: "Internet&Telecom", value: 0.85 },
          { name: "Computers & Electronics", value: 0.22 },
          { name: "News", value: 0.25 },
          { name: "Arts & Entertainment", value: 0.18 },
          { name: "Games", value: 0.37 },
          { name: "People & Society", value: 0.08},
          { name: "Sports", value: 0.18 },
          { name: "Online Communities", value: 0.05 },
          { name: "Reference", value: 0.05 },
          { name: "Hobbies & Leisure", value: 0.05 },
        ],
        devices: [
          { name: "Mobile", value: 0.90}, 
          { name: "Desktop", value: 0.06 },
          { name: "Tablet", value: 0.03 },
          { name: "ConnectedTV", value: 0.01 },
        ],
        os :[
          { name: "Android", value:0.50},
          { name: "IOS", value: 0.09},
          { name: "Windows", value: 0.19},
          { name: "OS X", value: 0.008},
          { name: "Linux", value: 0.0038}
        ],
        make:[
          { name:"Samsung", value:0.375},
          { name:"Apple", value:0.1626},
          { name:"Tecno", value:0.1039},
          { name:"Itel", value:0.09003},
          { name:"Huawei", value:0.0755},
          { name:"Xiaomi", value:0.0253},
          { name:"Nokia", value:0.0239},
          { name:"ZTE", value:0.0099},
          { name:"Vivo", value:0.008},
          { name:"Lenovo", value:0.0087},
          { name:"Alcatel", value:0.0081},
          { name:"Infinix", value:0.0068},
          { name:"Wiko", value:0.0069},
          { name:"Oppo", value:0.005},
          { name:"bq", value:0.0054},
          { name:"Motorola", value:0.00392},
          { name:"Realme", value:0.0029},
          { name:"LG", value:0.0024},
          { name:"Meizu", value:0.0012},
          { name:"Asus", value:0.0015},
          { name:"Google", value:0.0010},
          { name:"HTC", value:0.0013},
          { name:"Sony", value:0.0014},
          { name:"Neffos", value:0.0009},
          { name:"BBK", value:0.0006},
          { name:"Caterpillar", value:0.000669},
          { name:"Vodafone", value:0.0007},
          { name:"Lava", value:0.0005},
          { name:"Doogee", value:0.0006},
          { name:"Hisense", value:0.00059},
          { name:"OnePlus", value:0.00043},
          { name:"TCL", value:0.0004},
          { name:"Archos", value:0.0005},
          { name:"Sharp", value:0.0003},
          { name:"Blackview", value:0.0003},
          { name:"Infinex", value:0.0003},
          { name:"Luowan", value:0.0003},
          { name:"Gionee", value:0.0002},
          { name:"China Mobile", value:0.00169},
          { name:"Blu", value:0.0002},
          { name:"Chine Mobile", value:0.0002},
          { name:"RIM", value:0.0002},
          { name:"LeEco", value:0.0002},
          { name:"Honor", value:0.0002},
          { name:"Oukitel", value:0.0001},
          { name:"Acer", value:0.0001},
          { name:"White Label", value:0.0001},
          { name:"CAT", value:0.0001}
        ],
        inventory_sizes: [
          { name: "Native", value: 0.3 },
          { name: "320x50", value: 0.4 },
          { name: "300x250", value: 0.23 },
          { name: "320x100", value: 0.12 },
          { name: "300x100", value: 0.09 },
          { name: "300x50", value: 0.27 },
          { name: "Instertitial", value: 0.25 },
          { name: "250x250", value: 0.08 },
          { name: "336x280", value: 0.06 },
          { name: "468x60", value: 0.08 },
        ],
        formats: [
          { name: "Display", value: 0.65 },
          { name: "Video", value: 0.18},
          { name: "Audio", value: 0.01},
        ],
      },
      {
        name: "Gabon",
        total_impressions:657900000,
        total_uniques:789000,
        fraction:0.010565280231251,
        genders: [
          { name: "Male", value:0.49},
          { name: "Female", value:0.15},
        ],
        ages: [
          { name: "18-24", value:0.06},
          { name: "25-34", value:0.14},
          { name: "35-44", value:0.11},
          { name: "45-54", value:0.06},
          { name: "55-64", value:0.28},
          { name: "65+", value:0.01},
        ],
        verticals: [
          { name: "Internet&Telecom", value:0.97},
          { name: "Computers & Electronics", value:0.65},
          { name: "News", value:0.05},
          { name: "Arts & Entertainment", value:0.13},
          { name: "Games", value:0.19},
          { name: "People & Society", value:0.04},
          { name: "Sports", value:0.02},
          { name: "Hobbies & Leisure", value:0.02},
          { name: "Reference", value:0.02},
          { name: "Online Communities", value:0.03},
        ],
        devices: [
          { name: "Mobile", value:0.96},
          { name: "Desktop", value:0.02},
          { name: "Tablet", value:0.01},
          { name: "ConnectedTV", value:0.01},
        ],
        os :[
          { name: "Android", value:0.59},
          { name: "IOS", value: 0.10},
          { name: "Windows", value: 0.22},
          { name: "OS X", value: 0.116},
          { name: "Linux", value: 0.111}
        ],
        make:[
          { name:"Samsung", value:0.3853},
          { name:"Tecno", value:0.2443},
          { name:"Apple", value:0.1491},
          { name:"Itel", value:0.0668},
          { name:"Huawei", value:0.0510},
          { name:"Xiaomi", value:0.0231},
          { name:"Infinix", value:0.01790},
          { name:"Oppo", value:0.00442},
          { name:"Nokia", value:0.00450},
          { name:"Vivo", value:0.0026},
          { name:"Lenovo", value:0.0022},
          { name:"Realme", value:0.0022},
          { name:"OnePlus", value:0.0019},
          { name:"Motorola", value:0.001615},
          { name:"Sony", value:0.0017},
          { name:"LG", value:0.0016},
          { name:"Alcatel", value:0.001538},
          { name:"Google", value:0.00116},
          { name:"RIM", value:0.0006},
          { name:"Wiko", value:0.0005},
          { name:"BBK", value:0.0005},
          { name:"Asus", value:0.0004},
          { name:"BlackBerry", value:0.000338},
          { name:"CAT", value:0.000307},    
          { name:"HTC", value:0.0003},
          { name:"ZTE", value:0.0002},
          { name:"Archos", value:0.00016},
          { name:"Infinex", value:0.0002},
          { name:"Honor", value:0.0002},
          { name:"TCL", value:0.0003},
          { name:"Sharp", value:0.0002},
          { name:"Acer", value:0.0001},
        ],
        inventory_sizes: [
          { name: "Native", value:0.1},
          { name: "320x50", value:0.08},
          { name: "300x250", value:0.14},
          { name: "240x133", value:0.12},
          { name: "288x240", value:0.1},
          { name: "120x240", value:0.07},
          { name: "Instertitial", value:0.1},
          { name: "234x60", value:0.06},
          { name: "336x280", value:0.1},
          { name: "200x200", value: 0.12},
        ],
        formats: [
          { name: "Display", value:0.76},
          { name: "Video", value:0.20},
          { name: "Audio", value:0.01},
        ],
      },
      {
        name: "Zambia",
        total_impressions: 754200000,
        total_uniques: 2669000,
        fraction:0.012111771318452,
        genders: [
          { name: "Male", value:0.55},
          { name: "Female", value:0.32},
        ],
        ages: [
          { name: "18-24", value:0.20},
          { name: "25-34", value:0.37},
          { name: "35-44", value:0.17},
          { name: "45-54", value:0.05},
          { name: "55-64", value:0.07},
          { name: "65+", value:0.02},
        ],
        verticals: [
          { name: "Internet&Telecom", value:0.87},
          { name: "Computers & Electronics", value:0.25},
          { name: "News", value:0.14},
          { name: "Arts & Entertainment", value:0.24},
          { name: "Games", value:0.31},
          { name: "People & Society", value:0.08},
          { name: "Sports", value:0.04},
          { name: "Online Communities", value:0.06},
          { name: "Reference", value:0.05},
          { name: "Books & Literature", value:0.05},
        ],
        devices: [
          { name: "Mobile", value:0.94},
          { name: "Desktop", value:0.03},
          { name: "Tablet", value:0.02},
          { name: "ConnectedTV", value:0.01},
        ],
        os :[
          { name: "Android", value:0.6855},
          { name: "IOS", value: 0.07},
          { name: "Windows", value: 0.11},
          { name: "OS X", value: 0.056},
          { name: "Linux", value: 0.03}
        ],
        make:[
          { name:"Samsung", value:0.2077},
          { name:"Tecno", value:0.2062},
          { name:"Infinix", value:0.21383},
          { name:"Apple", value:0.1048},
          { name:"Itel", value:0.0804},
          { name:"Huawei", value:0.0679},
          { name:"Xiaomi", value:0.0584},
          { name:"Oppo", value:0.0136},
          { name:"Nokia", value:0.0098},
          { name:"Google", value:0.0079},
          { name:"Infinex", value:0.0042},
          { name:"Vivo", value:0.00394},
          { name:"Sony", value:0.00311},
          { name:"Motorola", value:0.00284},
          { name:"Lenovo", value:0.002369},
          { name:"LG", value:0.00226},
          { name:"HTC", value:0.002184},
          { name:"OnePlus", value:0.00182},
          { name:"BBK", value:0.0009},
          { name:"Realme", value:0.0009},
          { name:"Hisense", value:0.0009},
          { name:"Lava", value:0.0007},
          { name:"Caterpillar", value:0.0004},
          { name:"Meizu", value:0.0003},
          { name:"Kyocera", value:0.0003},
          { name:"Vodafone", value:0.0003},
          { name:"Honor", value:0.0003},
          { name:"Redmi", value:0.0003},
          { name:"Alcatel", value:0.0003},
          { name:"ZTE", value:0.0003},
          { name:"Wiko", value:0.0002},
          { name:"RIM", value:0.0002},
          { name:"CAT", value:0.0002},
          { name:"Blackview", value:0.00012},
          { name:"Asus", value:0.0001},
        ],
        inventory_sizes: [
          { name: "Native", value:0.2},
          { name: "320x50", value:0.13},
          { name: "300x250", value:0.12},
          { name: "234x60", value:0.04},
          { name: "120x240", value:0.04},
          { name: "300x50", value:0.1},
          { name: "Instertitial", value:0.15},
          { name: "250x250", value:0.09},
          { name: "336x280", value:0.07},
          { name: "200x200", value:0.06},
        ],
        formats: [
          { name: "Display", value:0.79},
          { name: "Video", value:0.09},
          { name: "Audio", value:0.01},
        ],
      },
      {
        name: "United Arab Emirates",
        total_impressions: 16680000000,
        total_uniques: 27870000,
        fraction:0.267865745945078,
        genders: [
          { name: "Male", value:0.32},
          { name: "Female", value:0.58},
        ],
        ages: [
          { name: "18-24", value:0.51},
          { name: "25-34", value:0.21},
          { name: "35-44", value:0.15},
          { name: "45-54", value:0.03},
          { name: "55-64", value:0.01},
          { name: "65+", value:0.01},
        //  0.92
        ],
        verticals: [
          { name: "Internet&Telecom", value: 0.8 },
          { name: "Computers & Electronics", value: 0.088 },
          { name: "News", value: 0.058 },
          { name: "Arts & Entertainment", value: 0.03 },
          { name: "Games", value: 0.03 },
          { name: "People & Society", value: 0.022 },
          { name: "Online Communities", value: 0.02 },
          { name: "Law & Government", value: 0.016 },
          { name: "Reference", value: 0.012 },
          { name: "Business & Industrial", value: 0.01 },

        ],
        devices: [
          { name: "Mobile", value:0.88},
          { name: "Desktop", value:0.07},
          { name: "Tablet", value:0.04},
          { name: "ConnectedTV", value:0.01},
        ],
        os :[
          { name: "Android", value:0.53},
          { name: "IOS", value: 0.15},
          { name: "Windows", value: 0.21},
          { name: "OS X", value: 0.056},
        ],
        make:[
          { name: "Samsung", value:0.25285},
          { name: "Apple", value:0.2594},
          { name: "Xiaomi", value:0.1230},
          { name: "Oppo", value:0.0725},
          { name: "Huawei", value:0.06997},
          { name: "Vivo", value:0.06026},
          { name: "Realme", value:0.0303},
          { name: "OnePlus", value:0.0172},
          { name: "Tecno", value:0.0133},
          { name: "Infinix", value:0.0123},
          { name: "Nokia", value:0.0069},
          { name: "Lenovo", value:0.00677},
          { name: "Itel", value:0.0040},
          { name: "Motorola", value:0.0037},
          { name: "Honor", value:0.0036},
          { name: "Google", value:0.00235},
          { name: "Infinex", value:0.0014},
          { name: "Sony", value:0.0013},
          { name: "Lava", value:0.0012},
          { name: "LG", value:0.00099},
          { name: "HTC", value:0.000803},
          { name: "Asus", value:0.0005},
          { name: "ZTE", value:0.0004},
          { name: "BBK", value:0.0003},
          { name: "Sharp", value:0.0002},
          { name: "TCL", value:0.0001},
          { name: "Alcatel", value:0.0001},
        ],
        inventory_sizes: [
          { name: "Native", value:0.17},
          { name: "320x50", value:0.19},
          { name: "300x250", value:0.12},
          { name: "Instertitial", value:0.03},
          { name: "360x722", value:0.02},
          { name: "234x60", value:0.06},
          { name: "120x240", value:0.22},
          { name: "300x50", value:0.02},
          { name: "250x250", value:0.04},
          { name: "336x280", value:0.12},
        ],
        formats: [
          { name: "Display", value:0.52},
          { name: "Video", value:0.36},
          { name: "Audio", value:0.08},
        ],
      },
      {
        name: "Rwanda",
        total_impressions: 240800000,
        total_uniques: 1094000,
        fraction:0.003867030672876,
        genders: [
          { name: "Male", value: 0.66 },
          { name: "Female", value: 0.24 },
        ],
        ages: [
          { name: "18-24", value: 0.27 },
          { name: "25-34", value: 0.29 },
          { name: "35-44", value: 0.27 },
          { name: "45-54", value: 0.04 },
          { name: "55-64", value: 0.03 },
          { name: "65+", value: 0.01 },
        ],
        verticals: [
          { name: "Internet&Telecom", value: 0.76 },
          { name: "Computers & Electronics", value: 0.32 },
          { name: "News", value: 0.21 },
          { name: "Arts & Entertainment", value: 0.27 },
          { name: "Games", value: 0.21 },
          { name: "People & Society", value: 0.11 },
          { name: "Sports", value: 0.07 },
          { name: "Law & Government", value: 0.06 },
          { name: "Reference", value: 0.13 },
          { name: "Online Communities", value: 0.09 },
        ],
        devices: [
          { name: "Mobile", value: 0.83 },
          { name: "Desktop", value: 0.12 },
          { name: "Tablet", value: 0.03 },
          { name: "ConnectedTV", value: 0.02},
        ],
        os :[
          { name: "Android", value:0.38},
          { name: "IOS", value: 0.08},
          { name: "Windows", value: 0.38},
          { name: "OS X", value: 0.026},
        ],
        make:[
          { name:"Tecno", value:0.25035},
          { name:"Samsung", value:0.2426},
          { name:"Apple", value:0.1666},
          { name:"Infinix", value:0.1162},
          { name:"Google", value:0.03202},
          { name:"Huawei", value:0.01578},
          { name:"Xiaomi", value:0.0162},
          { name:"Itel", value:0.0159},
          { name:"Sony", value:0.01299},
          { name:"Oppo", value:0.0066},
          { name:"Nokia", value:0.0062},
          { name:"Infinex", value:0.0362},
          { name:"LG", value:0.034},
          { name:"Vivo", value:0.002984},
          { name:"Realme", value:0.00312},
          { name:"OnePlus", value:0.00299},
          { name:"Motorola", value:0.00286},
          { name:"Lenovo", value:0.0016},
          { name:"Alcatel", value:0.0005},
          { name:"HTC", value:0.0004},
          { name:"RIM", value:0.0005},
          { name:"Lava", value:0.0003},
          { name:"Wiko", value:0.0003},
          { name:"Asus", value:0.0003},
          { name:"BlackBerry", value:0.0003},
          { name:"ZTE", value:0.0002},
          { name:"Sharp", value:0.0002},
          { name:"Honor", value:0.00022},
          { name:"Neffos", value:0.0002},
          { name:"Meizu", value:0.0001},
        ],
        inventory_sizes: [
          { name: "Native", value: 0.24 },
          { name: "320x50", value: 0.14 },
          { name: "300x250", value: 0.14 },
          { name: "320x100", value: 0.04 },
          { name: "interstitial", value: 0.12 },
          { name: "300x50", value: 0.1 },
          { name: "250x250", value: 0.09 },
          { name: "336x280", value: 0.08 },
          { name: "200x200", value: 0.05 },
        ],
        formats: [
          { name: "Display", value: 0.70 },
          { name: "Video", value: 0.12 },
          { name: "Audio", value: 0.08 },
        ],
      }
    ],
    genders: [
      { name: "Male", value: 0.48 },
      { name: "Female", value: 0.39},
    ],
    ages: [
      { name: "18-24", value: 0.16 },
      { name: "25-34", value: 0.27 },
      { name: "35-44", value: 0.21 },
      { name: "45-54", value: 0.07 },
      { name: "55-64", value: 0.1 },
      { name: "65+", value: 0.07},
    ],
    verticals: [
      { name: "Internet&Telecom", value: 0.41 },
      { name: "Computers & Electronics", value: 0.15 },
      { name: "News", value: 0.07 },
      { name: "Arts & Entertainment", value: 0.08 },
      { name: "Games", value: 0.15 },
      { name: "People & Society", value: 0.04 },
      { name: "Sports", value: 0.02 },
      { name: "Online Communities", value: 0.03 },
      { name: "Reference", value: 0.02 },
      { name: "Books & Literature", value: 0.02 },
    ],
    devices: [
      { name: "Mobile", value: 0.93 },
      { name: "Desktop", value: 0.04 },
      { name: "Tablet", value: 0.03 },
      { name: "ConnectedTV", value: 0.0001 },
    ],
    os :[
      { name: "Android", value:0.05},
      { name: "IOS", value: 0.01},
      { name: "Windows", value: 0.02},
      { name: "OS X", value: 0.04},
    ],
    make:[
      { name: "Apple", value:0.2821},
      { name: "Samsung", value:0.2726},
      { name: "Xiaomi", value:0.1235},
      { name: "Oppo", value:0.061},
      { name: "Huawei", value:0.0504},
      { name: "Vivo", value:0.0476},
      { name: "Realme", value:0.0349},
      { name: "Motorola", value:0.029},
      { name: "OnePlus", value:0.011},
      { name: "Tecno", value:0.0099},
      { name: "Infinix", value:0.0097},
      { name: "LG", value:0.0076},
      { name: "Google", value:0.0075},
      { name: "Nokia", value:0.0045},
      { name: "Lenovo", value:0.0031},
      { name: "Sony", value:0.0028},
      { name: "Itel", value:0.0024},
      { name: "Asus", value:0.0020},
      { name: "Honor", value:0.0011},
      { name: "ZTE", value:0.0010},
      { name: "Infinex", value:0.0007},
      { name: "BBK", value:0.00072},
      { name: "Alcatel", value:0.0007},
      { name: "HTC", value:0.0006},
      { name: "TCL", value:0.0005},
      { name: "T-Mobile", value:0.0005},
      { name: "Casper", value:0.0005},
      { name: "Reeder", value:0.0004},
      { name: "Vsmart", value:0.0003},
      { name: "Vestel", value:0.0003},
      { name: "Lava", value:0.0003},
      { name: "Meizu", value:0.0002},
      { name: "Hisense", value:0.0002},
      { name: "Jio", value:0.0001},
      { name: "Gionee", value:0.0002},
      { name: "Micromax", value:0.0002},
      { name: "Sharp", value:0.0002},
      { name: "Kyocera", value:0.0001},
      { name: "Vodafone", value:0.0001},
      { name: "CAT", value:0.0001},
      { name: "Wiko", value:0.0001},
      { name: "Mixc", value:0.0001},
      { name: "Cricket", value:0.0001},
      { name: "Blu", value:0.00008},
    ],
    inventory_sizes: [
      { name: "Native", value: 0.33 },
      { name: "320x50", value: 0.27 },
      { name: "300x250", value: 0.18},
      { name: "320x100", value: 0.08 },
      { name: "300x100", value: 0.06 },
      { name: "300x50", value: 0.17 },
      { name: "Instertitial", value: 0.22 },
      { name: "250x250", value: 0.05 },
      { name: "336x280", value: 0.13 },
      { name: "234x60", value: 0.06 },
    ],
    formats: [
      { name: "Display", value: 0.74 },
      { name: "Video", value: 0.15 },
      { name: "Audio", value: 0.01 },
    ],
    selected_filters_count:0
  }),
  watch: {
    selected_countries(newVal, oldVal) {
      if (this.selected_countries.length > 0) {
        this.selected_countries.map((country) => {
          if (!this.selected_values.includes(country)) {
            this.selected_values.push(country);
          }
        });this.handleDeselect(newVal, oldVal);
      }
      if (this.selected_countries.length === 0) {
        this.selected_values = this.selected_values.filter((value) => {
          return !this.countries.includes(value);
        });
      }
    },
    selected_gender(newVal, oldVal) {
      if (this.selected_gender.length > 0) {
        this.selected_gender.map((gender) => {
          if (!this.selected_values.includes(gender)) {
            this.selected_values.push(gender);
          }
        });
        this.handleDeselect(newVal, oldVal);
      }
      if (this.selected_gender.length === 0) {
        this.selected_values = this.selected_values.filter((value) => {
          return !this.genders.includes(value);
        });
      }
    },
    selected_ages(newVal, oldVal) {
      if (this.selected_ages.length > 0) {
        this.selected_ages.map((age) => {
          if (!this.selected_values.includes(age)) {
            this.selected_values.push(age);
          }
        });
        this.handleDeselect(newVal, oldVal);
      }
      if (this.selected_ages.length === 0) {
        this.selected_values = this.selected_values.filter((value) => {
          return !this.ages.includes(value);
        });
      }
    },
    selected_verticals(newVal, oldVal) {
      if (this.selected_verticals.length > 0) {
        this.selected_verticals.map((vertical) => {
          if (!this.selected_values.includes(vertical)) {
            this.selected_values.push(vertical);
          }
        });
        this.handleDeselect(newVal, oldVal);
      }
      if (this.selected_verticals.length === 0) {
        this.selected_values = this.selected_values.filter((value) => {
          return !this.verticals.includes(value);
        });
      }
    },
    selected_os(newVal, oldVal){
      if (this.selected_os.length > 0) {
        this.selected_os.map((os) => {
          if (!this.selected_values.includes(os)) {
            this.selected_values.push(os);
          }
        });
        this.handleDeselect(newVal, oldVal);
      }
      if (this.selected_os.length === 0) {
        this.selected_values = this.selected_values.filter((value) => {
          return !this.os.includes(value);
        });
      }
    },
    selected_devices(newVal, oldVal) {
      if (this.selected_devices.length > 0) {
        this.selected_devices.map((device) => {
          if (!this.selected_values.includes(device)) {
            this.selected_values.push(device);
          }
        });
        this.handleDeselect(newVal, oldVal);
      }
      if (this.selected_devices.length === 0) {
        this.selected_values = this.selected_values.filter((value) => {
          return !this.devices.includes(value);
        });
      }
    },
    selected_make(newVal, oldVal) {
      if (this.selected_make.length > 0) {
        this.selected_make.map((make) => {
          if (!this.selected_values.includes(make)) {
            this.selected_values.push(make);
          }
        });
        this.handleDeselect(newVal, oldVal);
      }
      if (this.selected_make.length === 0) {
        this.selected_values = this.selected_values.filter((value) => {
          return !this.make.includes(value);
        });
      }
    },
    selected_inventory_size(newVal, oldVal) {
      if (this.selected_inventory_size.length > 0) {
        this.selected_inventory_size.map((size) => {
          if (!this.selected_values.includes(size)) {
            this.selected_values.push(size);
          }
        });
        this.handleDeselect(newVal, oldVal);
      }
      if (this.selected_inventory_size.length === 0) {
        this.selected_values = this.selected_values.filter((value) => {
          return !this.inventory_sizes.includes(value);
        });
      }
    },
    selected_format(newVal, oldVal) {
      if (this.selected_format.length > 0) {
        this.selected_format.map((format) => {
          if (!this.selected_values.includes(format)) {
            this.selected_values.push(format);
          }
        });
        this.handleDeselect(newVal, oldVal);
      }
      if (this.selected_format.length === 0) {
        this.selected_values = this.selected_values.filter((value) => {
          return !this.formats.includes(value);
        });
      }
    },
    selected_values() {
      if (this.selected_values.length) {
        this.impressions = this.getValues(this.selected_values).impressions;
        this.uniques = this.getValues(this.selected_values).uniques;
      } else {
        this.impressions = [];
        this.uniques = [];
      }
    },
  },
  methods: {
    getValues(values) {
      let forecast = { impressions: [], uniques: [] };
      const countries = this.countries.map((item) => item.name);
      const genders = this.genders.map((item) => item.name);
      const ages = this.ages.map((item) => item.name);
      const verticals = this.verticals.map((item) => item.name);
      const devices = this.devices.map((item) => item.name);
      const inventory_sizes = this.inventory_sizes.map((item) => item.name);
      const formats = this.formats.map((item) => item.name);
      const os = this.os.map((item) => item.name);
      const make = this.make.map((item) => item.name);
      const hasOnlyCountries = values.every((item) =>
        countries.includes(item.name)
      );
      const hasOnlyGenders = values.every((item) =>
        genders.includes(item.name)
      );
      const hasOnlyAges = values.every((item) => ages.includes(item.name));
      const hasOnlyVerticals = values.every((item) =>
        verticals.includes(item.name)
      );
      const hasOnlyDevices = values.every((item) =>
        devices.includes(item.name)
      );
      const hasOnlySizes = values.every((item) =>
        inventory_sizes.includes(item.name)
      );
      const hasOnlyFormats = values.every((item) =>
        formats.includes(item.name)
      );
      const hasOnlyOs = values.every((item) =>
        os.includes(item.name)
      );
      const hasOnlyMake = values.every((item) =>
      make.includes(item.name)
    );
      if (values.length) {
        if (hasOnlyCountries) {
          let impressions = values.map((item) => item.total_impressions);
          let uniques = values.map((item) => item.total_uniques);
          forecast.impressions.push(
            impressions.reduce((acc, curr) => acc + curr, 0)
          );
          forecast.uniques.push(uniques.reduce((acc, curr) => acc + curr, 0));
          return forecast;
        }

        if (
          hasOnlyGenders ||
          hasOnlyAges ||
          hasOnlyVerticals ||
          hasOnlyDevices ||
          hasOnlySizes ||
          hasOnlyFormats ||
          hasOnlyOs ||
          hasOnlyMake
        ) {
          let summedRatios = values.reduce(
            (accumulator, object) => accumulator + object.value,
            0
          );
          let impressions = summedRatios * this.total_impressions;
          let uniques = summedRatios * this.total_uniques;
          forecast.impressions.push(impressions.toFixed(0));
          forecast.uniques.push(uniques.toFixed(0));
          return forecast;
        }
        //Has multiple types of filters
        //Check for countries as well,
        if (
          (!hasOnlyGenders && !hasOnlyAges) ||
          !hasOnlyVerticals ||
          !hasOnlyDevices ||
          !hasOnlySizes ||
          !hasOnlyFormats ||
          !hasOnlyOs ||
          !hasOnlyMake
        ) {
          //console.log("different filters")
          //If countries in the selected values use the filter values inside the countries..
          if (!hasOnlyCountries) {
            //console.log("countries in the selected values")
            const includesCountry = this.countries.some(item1 => 
              values.some(item2 => item1.name === item2.name)
            );
            //Check if any countries are in selected values, apply country filters
            if(includesCountry){
              let country_impressions  = [];
              let country_uniques =[];
              let allValues = []; //array or arrays of each country's values
              //check has gender
              for(let i=0; i< this.selected_countries.length; i++){
                country_impressions.push(this.selected_countries[i].total_impressions);
                country_uniques.push(this.selected_countries[i].total_uniques);
                let countryValues = [];
                if(this.genders.some(item1 => values.some(item2 => item1.name === item2.name)) && this.genders.length){
                  //Check selected gender in selected country
                  let values = [];
                  for(let j=0; j<this.selected_gender.length; j++){
                    values.push(this.selected_countries[i].genders.filter(item => item.name === this.selected_gender[j].name)[0].value);
                  }
                  let sum = values.reduce((accumulator, item) => accumulator + item, 0).toFixed(2);
                  countryValues.push(sum);
                }
                //Check has ages
                if(this.ages.some(item1 => values.some(item2 => item1.name === item2.name)) && this.ages.length){
                  //Check selected ages in selected country
                  let values = [];
                  for(let j=0; j<this.selected_ages.length; j++){
                    if(this.selected_countries[i].ages.filter(item => item.name === this.selected_ages[j].name).length > 0){
                      values.push(this.selected_countries[i].ages.filter(item => item.name === this.selected_ages[j].name)[0].value);
                    }
                  }
                  let sum = values.reduce((accumulator, item) => accumulator + item, 0).toFixed(2);
                  countryValues.push(sum);
                }
                //Check has verticals
                if(this.verticals.some(item1 => values.some(item2 => item1.name === item2.name)) && this.verticals.length){
                  //Check selected ages in selected country
                  let values = [];
                  for(let j=0; j<this.selected_verticals.length; j++){
                    if(this.selected_countries[i].verticals.filter(item => item.name === this.selected_verticals[j].name).length > 0){
                      values.push(this.selected_countries[i].verticals.filter(item => item.name === this.selected_verticals[j].name)[0].value);
                    }
                  }
                  let sum = values.reduce((accumulator, item) => accumulator + item, 0).toFixed(2);
                  countryValues.push(sum);
                }
                //Check has devices
                if(this.devices.some(item1 => values.some(item2 => item1.name === item2.name)) && this.devices.length){
                  //Check selected ages in selected country
                  let values = [];
                  for(let j=0; j<this.selected_devices.length; j++){
                    if(this.selected_countries[i].devices.filter(item => item.name === this.selected_devices[j].name).length > 0){
                      values.push(this.selected_countries[i].devices.filter(item => item.name === this.selected_devices[j].name)[0].value);
                    }
                  }
                  let sum = values.reduce((accumulator, item) => accumulator + item, 0).toFixed(2);
                  countryValues.push(sum);
                }
                //Check has inventory sizes
                if(this.inventory_sizes.some(item1 => values.some(item2 => item1.name === item2.name)) && this.inventory_sizes.length){
                  //Check selected ages in selected country
                  let values = [];
                  for(let j=0; j<this.selected_inventory_size.length; j++){
                    if(this.selected_countries[i].inventory_sizes.filter(item => item.name === this.selected_inventory_size[j].name).length > 0){
                      values.push(this.selected_countries[i].inventory_sizes.filter(item => item.name === this.selected_inventory_size[j].name)[0].value);
                    }
                  }
                  let sum = values.reduce((accumulator, item) => accumulator + item, 0).toFixed(2);
                  countryValues.push(sum);
                }
                //Check has format
                if(this.formats.some(item1 => values.some(item2 => item1.name === item2.name)) && this.formats.length){
                  //Check selected ages in selected country
                  let values = [];
                  for(let j=0; j<this.selected_format.length; j++){
                    if(this.selected_countries[i].formats.filter(item => item.name === this.selected_format[j].name).length > 0){
                      values.push(this.selected_countries[i].formats.filter(item => item.name === this.selected_format[j].name)[0].value);
                    }
                  }
                  let sum = values.reduce((accumulator, item) => accumulator + item, 0).toFixed(2);
                  countryValues.push(sum);
                }
                //Check has os
                if(this.os.some(item1 => values.some(item2 => item1.name === item2.name)) && this.os.length){
                  let values = [];
                  for(let j=0; j<this.selected_os.length; j++){
                    if(this.selected_countries[i].os.filter(item => item.name === this.selected_os[j].name).length > 0){
                      values.push(this.selected_countries[i].os.filter(item => item.name === this.selected_os[j].name)[0].value);
                    }
                  }
                  let sum = values.reduce((accumulator, item) => accumulator + item, 0).toFixed(2);
                  countryValues.push(sum);
                }
                //Check has make
                if(this.make.some(item1 => values.some(item2 => item1.name === item2.name)) && this.make.length){
                  let values = [];
                  for(let j=0; j<this.selected_make.length; j++){
                    if(this.selected_countries[i].make.filter(item => item.name === this.selected_make[j].name).length > 0){
                      values.push(this.selected_countries[i].make.filter(item => item.name === this.selected_make[j].name)[0].value);
                    }
                  }
                  let sum = values.reduce((accumulator, item) => accumulator + item, 0).toFixed(2);
                  countryValues.push(sum);
                }
                allValues.push(countryValues);              
              }
              //console.log("allValues",allValues);

              //console.log("allValues.length", allValues.length)
              let sum_country_impressions = country_impressions.reduce((accumulator, item) => accumulator + item,0);
              let sum_country_uniques = country_uniques.reduce((accumulator, item) => accumulator + item,0)
              if(allValues.length === 1){
                let products = [];
                for(let i=0; i<allValues.length; i++){
                  let product = allValues[i].reduce(
                    (accumulator, item) => accumulator * item,
                    1
                  );
                  products.push(product)
                }
                let sum_products = products.reduce((acc, item)=>acc +item,0);
                let impressions = sum_products * sum_country_impressions;
                let uniques = sum_products * sum_country_uniques;
                forecast.impressions.push(impressions.toFixed(0));
                forecast.uniques.push(uniques.toFixed(0));
                return forecast;
              }else if(allValues.length > 1){
                let products = [];
                for(let i=0; i<allValues.length; i++){
                  let product = allValues[i].reduce(
                    (accumulator, item) => accumulator * item,
                    1
                  );
                  products.push(product)
                }
                let country_values_impressions = [];
                let country_values_uniques = [];
                for (let j = 0; j < products.length; j++) {
                  let impressions_result = products[j] * country_impressions[j];
                  let uniques_result = products[j] * country_uniques[j];
                  country_values_impressions.push(impressions_result);
                  country_values_uniques.push(uniques_result)
                }
                let impressions = country_values_impressions.reduce(
                  (accumulator, item) => accumulator + item, 0
                )
                let uniques = country_values_uniques.reduce(
                  (accumulator, item) => accumulator + item, 0
                )
                forecast.impressions.push(impressions.toFixed(0));
                forecast.uniques.push(uniques.toFixed(0));
                return forecast;
              }
            } else {
              let all_selected = [];
              if(this.genders.some(item1 => values.some(item2 => item1.name === item2.name)) && this.genders.length){
                let values = [];
                for(let j=0; j<this.selected_gender.length; j++){
                  values.push(this.selected_gender[j].value);
                }
                let sum = values.reduce((accumulator, item) => accumulator + item, 0).toFixed(2);
                all_selected.push(sum)
              }
              if(this.ages.some(item1 => values.some(item2 => item1.name === item2.name)) && this.ages.length){
                let values = [];
                for(let j=0; j<this.selected_ages.length; j++){
                  values.push(this.selected_ages[j].value);
                }
                let sum = values.reduce((accumulator, item) => accumulator + item, 0).toFixed(2);
                all_selected.push(sum)
              }
              if(this.verticals.some(item1 => values.some(item2 => item1.name === item2.name)) && this.verticals.length){
                let values = [];
                for(let j=0; j<this.selected_verticals.length; j++){
                  values.push(this.selected_verticals[j].value);
                }
                let sum = values.reduce((accumulator, item) => accumulator + item, 0).toFixed(2);
                all_selected.push(sum)
              }
              if(this.devices.some(item1 => values.some(item2 => item1.name === item2.name)) && this.devices.length){
                let values = [];
                for(let j=0; j<this.selected_devices.length; j++){
                  values.push(this.selected_devices[j].value);
                }
                let sum = values.reduce((accumulator, item) => accumulator + item, 0).toFixed(2);
                all_selected.push(sum)
              }
              if(this.inventory_sizes.some(item1 => values.some(item2 => item1.name === item2.name)) && this.inventory_sizes.length){
                let values = [];
                for(let j=0; j<this.selected_inventory_size.length; j++){
                  values.push(this.selected_inventory_size[j].value);
                }
                let sum = values.reduce((accumulator, item) => accumulator + item, 0).toFixed(2);
                all_selected.push(sum)
              }
              if(this.formats.some(item1 => values.some(item2 => item1.name === item2.name)) && this.formats.length){
                let values = [];
                for(let j=0; j<this.selected_format.length; j++){
                  values.push(this.selected_format[j].value);
                }
                let sum = values.reduce((accumulator, item) => accumulator + item, 0).toFixed(2);
                all_selected.push(sum)
              }
              if(this.os.some(item1 => values.some(item2 => item1.name === item2.name)) && this.os.length){
                let values = [];
                for(let j=0; j<this.selected_os.length; j++){
                  values.push(this.selected_os[j].value);
                }
                let sum = values.reduce((accumulator, item) => accumulator + item, 0).toFixed(2);
                all_selected.push(sum)
              }
              if(this.make.some(item1 => values.some(item2 => item1.name === item2.name)) && this.make.length){
                let values = [];
                for(let j=0; j<this.selected_make.length; j++){
                  values.push(this.selected_make[j].value);
                }
                let sum = values.reduce((accumulator, item) => accumulator + item, 0).toFixed(2);
                all_selected.push(sum)
              }
              let products = all_selected.reduce(
                (accumulator, item) => accumulator * item,
                1
              );
              let impressions = products * this.total_impressions;
              let uniques =products * this.total_uniques;
              forecast.impressions.push(impressions.toFixed(0));
              forecast.uniques.push(uniques.toFixed(0));
              return forecast;
            }
          }
        }
      }
    },

    handleDeselect(newVal, oldVal) {
      const removedValue = oldVal.find((val) => !newVal.includes(val));
      if (removedValue && this.selected_values.includes(removedValue)) {
        // Remove deselected value from this.selected_values
        this.selected_values.splice(
          this.selected_values.indexOf(removedValue),
          1
        );
      }
    },
  },
};

